import { Flex, HStack, StackProps, VStack, chakra } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, HelpIcon, NextLink, Popover, Text, TokenYoloIcon, TooltipText } from "@looksrare/uikit";
import { formatToSignificant, toDecimals } from "@looksrare/utils";
import { DataPoint } from "@/views/Rewards/components/HistoricSeason/DataPoint";
import { ClaimVaultShareRewardsButton } from "@/components/Buttons/ClaimVaultShareRewardsButton";
import { useConvertSharesToAssets } from "@/hooks";

interface LiquidityRewardsCardProps extends StackProps {
  isLoading: boolean;
  proof: string[] | null | undefined;
  currentClaimableAmountVum: bigint;
  hasClaimed: boolean;
  onSuccess: () => void;
}

export const LiquidityRewardsCard = ({
  proof,
  currentClaimableAmountVum,
  hasClaimed,
  isLoading,
  onSuccess,
  ...props
}: LiquidityRewardsCardProps) => {
  const { t } = useTranslation();

  const sharesToAssetsQuery = useConvertSharesToAssets("GAMES_YOLO_LP_V1_BLAST", currentClaimableAmountVum);
  const sharesToAssets = sharesToAssetsQuery.isSuccess ? sharesToAssetsQuery.data : 0n;

  const isBelowMinClaimable = sharesToAssets < toDecimals("0.0001");
  const seasonStartTsMs = Date.parse("2024-06-12T16:00:00.000Z");
  const isBeforeClaimableTs = seasonStartTsMs > Date.now();

  return (
    <VStack
      borderRadius="dialog"
      bg="ui-01"
      p="24px"
      borderColor="border-01"
      borderWidth="1px"
      alignItems="stretch"
      spacing={4}
      {...props}
    >
      <HStack alignItems="center">
        <Text textStyle="detail" color="text-03" bold>
          {t("{{token}} Allocated to Liquidity Pool", { token: "YOLO" })}
        </Text>
        <Popover
          label={
            <TooltipText>
              {t(
                "{{percentage}}% of Season 1 YOLO token rewards have been distributed to the YOLO liquidity pool. Withdraw at any time, or keep providing liquidity to earn S2 points and more YOLO!",
                { percentage: 30 }
              )}
            </TooltipText>
          }
        >
          <chakra.span>
            <HelpIcon color="text-03" mb={1} boxSize="16px" />
          </chakra.span>
        </Popover>
      </HStack>
      <Flex>
        <DataPoint
          flex={1}
          isLoading={isLoading}
          value={
            <>
              {formatToSignificant(sharesToAssets, 1)}
              <TokenYoloIcon boxSize="16px" />
            </>
          }
          label={t("Total")}
        />
        {(() => {
          if (isBelowMinClaimable || hasClaimed) {
            return (
              <Button flex={1} colorScheme="secondary" as={NextLink} href="/liquidity">
                {t("View Liquidity")}
              </Button>
            );
          }
          return (
            <ClaimVaultShareRewardsButton
              flex={1}
              colorScheme="brand"
              isDisabled={isBeforeClaimableTs || isBelowMinClaimable}
              currentClaimableAmountVum={currentClaimableAmountVum}
              proof={proof}
              onSuccess={onSuccess}
            >
              {t("Claim")}
            </ClaimVaultShareRewardsButton>
          );
        })()}
      </Flex>
    </VStack>
  );
};
