import { useTranslation } from "next-i18next";
import { AspectRatio, Box, BoxProps, Flex, Stack } from "@chakra-ui/react";
import { CloudinaryImage, Text, NextLink, EyeIcon } from "@looksrare/uikit";
import { usePtbPageViews, usePtbActivePlayers } from "@looksrare/poke-the-bear/hooks";
import { useYoloPageViews, useYoloActivePlayers } from "@looksrare/yolo";
import { useModPageViews, useModActivePlayers } from "@looksrare/moon-or-doom";
import { useQuantumActivePlayers, useQuantumPageViews } from "@looksrare/classic-games/quantum";
import { useFlipperActivePlayers, useFlipperPageViews } from "@looksrare/classic-games/flipper";
import { useLaserBlastActivePlayers, useLaserBlastPageViews } from "@looksrare/classic-games/laser-blast";
import { useDontFallInActivePlayers, useDontFallInPageViews } from "@looksrare/classic-games/dont-fall-in/realtime";
import { shadows } from "@/theme/shadows";
import { CountTag } from "./CountTag";

const gamePageViewsFunctions: Record<string, () => { count: number } | null> = {
  yolo: useYoloPageViews,
  ptb: usePtbPageViews,
  mod: useModPageViews,
  quantum: useQuantumPageViews,
  flipper: useFlipperPageViews,
  laserblast: useLaserBlastPageViews,
  dontfallin: useDontFallInPageViews,
};

const gamePlayerFunctions: Record<string, () => number | null> = {
  yolo: useYoloActivePlayers,
  ptb: usePtbActivePlayers,
  mod: useModActivePlayers,
  quantum: useQuantumActivePlayers,
  flipper: useFlipperActivePlayers,
  laserblast: useLaserBlastActivePlayers,
  dontfallin: useDontFallInActivePlayers,
};

export interface PopularGameCardProps extends BoxProps {
  title: string;
  description: string;
  href: string;
  imageSrc: string;
  blurImageSrc?: string;
  gameId: string;
}

export const PopularGameCard = ({
  title,
  description,
  href,
  imageSrc,
  blurImageSrc,
  gameId,
  ...props
}: PopularGameCardProps) => {
  const { t } = useTranslation();
  const watchingAmount = gamePageViewsFunctions[gameId]?.()?.count;
  const playingAmount = gamePlayerFunctions[gameId]?.();

  const cardHoverSx = {
    transform: "translateY(0px)",
    transition: "all 200ms ease-out 0s",
    cursor: "pointer",
    _hover: {
      background: "hover-ui",
      boxShadow: shadows["shadow-card-dark-hover"],
      transform: "translateY(-2px)",
    },
    _active: {
      background: "onclick-ui",
      boxShadow: shadows["shadow-card-dark-hover"],
      transform: "translateY(-2px)",
    },
  };

  return (
    <Stack as={NextLink} href={href} spacing={0} borderRadius="dialog" overflow="hidden" sx={cardHoverSx} {...props}>
      <AspectRatio ratio={16 / 9}>
        <CloudinaryImage src={imageSrc} alt="" layout="fill" sizes="442px" objectFit="cover" />
      </AspectRatio>
      {(watchingAmount || playingAmount) && (
        <Flex position="absolute" left={2} top="10px" gap={2}>
          {watchingAmount && <CountTag text={t("{{count}} Watching", { count: watchingAmount })} icon={EyeIcon} />}
        </Flex>
      )}

      <Box position="relative" backgroundColor="ui-01">
        <CloudinaryImage
          src={imageSrc}
          alt=""
          layout="fill"
          sizes="442px"
          objectFit="cover"
          placeholder={blurImageSrc ? "blur" : "empty"}
          blurDataURL={blurImageSrc}
        />

        <Stack spacing={2} p={4} pb={5} backdropFilter="blur(128px)" height="120px">
          <Text textStyle="heading-03" color="white" textShadow={shadows["shadow-text-on-image"]} bold>
            {title}
          </Text>

          <Text
            textStyle="detail"
            color="text-02"
            textShadow={shadows["shadow-text-on-image"]}
            whiteSpace="pre-wrap"
            textOverflow="ellipsis"
            overflow="hidden"
            noOfLines={2}
          >
            {description}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};
