import { Flex, HStack, StackProps, VStack, chakra } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, HelpIcon, Popover, Text, TokenYoloIcon, TooltipText } from "@looksrare/uikit";
import { formatToSignificant, useAssertNetworkDisclosure } from "@looksrare/utils";
import { useAccount } from "wagmi";
import { DataPoint } from "@/views/Rewards/components/HistoricSeason/DataPoint";
import { ClaimTokenModal } from "@/views/Rewards/components/HistoricSeason/ClaimTokenModal";
import { useInvalidateUser } from "@/queries/user";

interface Props extends StackProps {
  isLoading: boolean;
  proof: string[] | null | undefined;
  currentClaimableAmountWei: bigint;
  claimedWei: bigint;
  onSuccess: () => void;
  hasPlayedGames: boolean;
}

export const ClaimableTokenRewardsCard = ({
  isLoading,
  proof,
  currentClaimableAmountWei,
  claimedWei,
  onSuccess,
  hasPlayedGames,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { isOpen, onOpen, onClose } = useAssertNetworkDisclosure({ network: "blast" });
  const invalidateUser = useInvalidateUser();
  const displayDecimals = 4;
  const readyToClaimDisplay: string = formatToSignificant(currentClaimableAmountWei, displayDecimals);
  const isBelowMinClaimable = currentClaimableAmountWei < BigInt(10 ** (18 - displayDecimals)); // Treat < 0.0001 as 0

  return (
    <>
      <VStack
        borderRadius="dialog"
        bg="ui-01"
        p="24px"
        borderColor="border-01"
        borderWidth="1px"
        alignItems="stretch"
        spacing={4}
        {...props}
      >
        <HStack alignItems="center">
          <Text textStyle="detail" color="text-03" bold>
            {t("Unlocked & Claimable")}
          </Text>
          <Popover
            label={
              <TooltipText>
                {t(
                  "{{percentage}}% of Season 1 YOLO token rewards were unlocked immediately at the end of the season. Every {{minutes}} minutes for 3 months, more YOLO tokens are unlocked and available to claim.",
                  { percentage: 20, minutes: 10 }
                )}
              </TooltipText>
            }
          >
            <chakra.span>
              <HelpIcon color="text-03" mb={1} boxSize="16px" />
            </chakra.span>
          </Popover>
        </HStack>
        <Flex>
          <DataPoint
            isLoading={isLoading}
            value={
              <>
                {readyToClaimDisplay} <TokenYoloIcon boxSize="16px" />
              </>
            }
            label={t("Ready to Claim")}
          />
          <Button
            isLoading={isLoading}
            flex={1}
            colorScheme="brand"
            isDisabled={isBelowMinClaimable}
            onClick={() => {
              !!address && invalidateUser(address);
              onOpen();
            }}
          >
            {t("Claim")}
          </Button>
        </Flex>
      </VStack>

      <ClaimTokenModal
        isOpen={isOpen}
        onClose={onClose}
        proof={proof}
        onSuccess={onSuccess}
        claimedWei={claimedWei}
        currentClaimableAmountWei={currentClaimableAmountWei}
        hasPlayedGames={hasPlayedGames}
      />
    </>
  );
};
