import { useState } from "react";
import { useTranslation } from "next-i18next";
import { Box, Flex, Grid, GridItem, HStack, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Button, CheckmarkFilledIcon, Chevron, EthTokenIcon, Text, currencyTokenIconMap } from "@looksrare/uikit";
import type {
  LiquidityPoolContract,
  LiquidityPoolGame,
  LiquidityPoolInterval,
  LiquidityPoolMetricsFilterInput,
} from "@looksrare/yolo-games-gql-typegen";
import { useLiquidityPools } from "../hooks";
import { gameContractToDisplay, liquidityPoolGames } from "../utils";
import { GlobalRoundPlayed, InflowsOutflows, CumulativePnL, GlobalFeesEarned, GlobalGameVolume } from "./Charts";
import { TimeIntervalSelectMenu } from "./TimeIntervalSelectMenu";

export const LiquidityGlobalPerformance = () => {
  const { t } = useTranslation();

  // Filters
  const [gameContract, setGameContract] = useState<LiquidityPoolGame | "ALL_GAMES">("ALL_GAMES");
  const [filters, setFilters] = useState<LiquidityPoolMetricsFilterInput>({
    contract: "GAMES_ETH_LP_V1_BLAST",
    timeInterval: "_1M",
  });

  // Queries
  const liquidityPoolsQuery = useLiquidityPools({});

  // Currently selected pool data
  const currentPool = liquidityPoolsQuery.data?.find((pool) => pool.contract === filters.contract);
  const symbol = currentPool?.currency.symbol ?? "ETH";
  const poolName = !!currentPool ? t("liq::{{token}} Pool", { token: symbol }) : "—";
  const PoolIcon = (() => {
    if (!currentPool) {
      return EthTokenIcon;
    }
    return currencyTokenIconMap[symbol];
  })();

  const handleUpdateTimeInterval = (newTimeInterval: LiquidityPoolInterval) => {
    setFilters((prevState) => ({
      ...prevState,
      timeInterval: newTimeInterval,
    }));
  };

  const handleUpdatePool = (newContract: LiquidityPoolContract) => {
    setFilters((prevState) => ({
      ...prevState,
      contract: newContract,
    }));
  };

  const { name: gameName, color: gameColor } = gameContractToDisplay(gameContract);

  return (
    <Box mb={8}>
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <Menu>
          <MenuButton
            as={Button}
            size="xs"
            colorScheme="secondary"
            isLoading={liquidityPoolsQuery.isLoading}
            leftIcon={<Box boxSize={5} bg={gameColor} borderRadius={10} />}
          >
            {gameName}
            <Chevron direction="down" boxSize={5} ml={2} color="text-03" />
          </MenuButton>
          <MenuList>
            {["ALL_GAMES" as const, ...liquidityPoolGames]?.map((contract) => {
              const { name, color } = gameContractToDisplay(contract);

              return (
                <MenuItem key={contract} onClick={() => setGameContract(contract)}>
                  <Box boxSize={5} bg={color} borderRadius={10} />
                  <Text flex={1} px={4} textStyle="detail">
                    {name}
                  </Text>
                  {gameContract === contract && <CheckmarkFilledIcon color="text-03" />}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
        <HStack>
          <Menu>
            <MenuButton
              as={Button}
              size="xs"
              colorScheme="secondary"
              isLoading={liquidityPoolsQuery.isLoading}
              leftIcon={<PoolIcon />}
            >
              {poolName}
              <Chevron direction="down" boxSize={5} ml={2} color="text-03" />
            </MenuButton>
            <MenuList>
              {liquidityPoolsQuery.data?.map(({ contract, currency }) => {
                const Icon = currencyTokenIconMap[currency.symbol];

                return (
                  <MenuItem key={contract} onClick={() => handleUpdatePool(contract)}>
                    <Icon boxSize={5} />
                    <Text flex={1} px={4} textStyle="detail">
                      {t("liq::{{token}} Pool", { token: currency.symbol })}
                    </Text>
                    {filters.contract === contract && <CheckmarkFilledIcon color="text-03" />}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
          <TimeIntervalSelectMenu
            currentTimeInterval={filters.timeInterval}
            onSelectTimeInterval={handleUpdateTimeInterval}
          />
        </HStack>
      </Flex>
      <Grid
        gridGap={4}
        gridTemplateColumns={{ base: "minmax(0, 1fr)", md: "repeat(6, minmax(0, 1fr))" }}
        minHeight="230px"
      >
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <GlobalFeesEarned poolIcon={PoolIcon} gameContract={gameContract} filters={filters} currencySymbol={symbol} />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <GlobalGameVolume poolIcon={PoolIcon} gameContract={gameContract} filters={filters} currencySymbol={symbol} />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <GlobalRoundPlayed gameContract={gameContract} filters={filters} currencySymbol={symbol} />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 3 }}>
          <CumulativePnL filters={filters} currencySymbol={symbol} poolIcon={PoolIcon} />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 3 }}>
          <InflowsOutflows filters={filters} currencySymbol={symbol} poolIcon={PoolIcon} />
        </GridItem>
      </Grid>
    </Box>
  );
};
