import { SupportedNetwork, useAssertNetworkDisclosure } from "@looksrare/utils";
import { Button, ButtonProps } from "@looksrare/uikit";
import { ClaimLiquiditySharesModal } from "@/components/Modals/ClaimLiquiditySharesModal";

interface Props extends Omit<ButtonProps, "onClick"> {
  proof: string[] | null | undefined;
  currentClaimableAmountVum: bigint;
  network?: SupportedNetwork;
  onSuccess: () => void;
}

export const ClaimVaultShareRewardsButton = ({
  proof,
  currentClaimableAmountVum,
  network = "blast",
  onSuccess,
  children,
  ...props
}: Props) => {
  const { isOpen, onOpen, onClose } = useAssertNetworkDisclosure({ network });

  return (
    <>
      <Button flex={1} colorScheme="brand" onClick={onOpen} {...props}>
        {children}
      </Button>
      <ClaimLiquiditySharesModal
        isOpen={isOpen}
        onClose={onClose}
        proof={proof}
        currentClaimableAmountVum={currentClaimableAmountVum}
        onSuccess={onSuccess}
      />
    </>
  );
};
