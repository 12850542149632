import { useTranslation } from "next-i18next";
import { Flex, Stack } from "@chakra-ui/react";
import { EthMonoIcon, LevelsFilledIcon } from "@looksrare/uikit";
import { formatNumberToLocale, formatToSignificant } from "@looksrare/utils";
import { useGameStats } from "@/queries/stats";
import { SectionHeader } from "./SectionHeader";
import { StatBox } from "./StatBox";

export const PlatformStats = () => {
  const { t } = useTranslation();
  const { data: gameStats } = useGameStats();
  const { totalPlayers, totalGamesPlayed, volumePlayedWei } = gameStats || {};
  const totalPlayersDisplay = totalPlayers ? formatNumberToLocale(parseInt(totalPlayers.toString()), 0) : "-";
  const totalGamesPlayedDisplay = totalGamesPlayed
    ? formatNumberToLocale(parseInt(totalGamesPlayed.toString()), 0)
    : "-";
  const totalVolumeDisplay = volumePlayedWei ? formatToSignificant(volumePlayedWei, 0) : "-";

  return (
    <Stack spacing={4} px={{ base: 4, lg: 12 }}>
      <SectionHeader icon={LevelsFilledIcon} title={t("Platform Stats")} />

      <Flex flexDirection={{ base: "column", md: "row" }} width="100%" gap={3}>
        <StatBox flex={1} headerText={t("Total Players")} value={totalPlayersDisplay} />
        <StatBox flex={1} headerText={t("Total Game Volume")} value={totalVolumeDisplay} icon={EthMonoIcon} />
        <StatBox flex={1} headerText={t("Total Games Played")} value={totalGamesPlayedDisplay} />
      </Flex>
    </Stack>
  );
};
