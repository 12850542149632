import { Trans } from "next-i18next";
import { formatNumberToLocale } from "@looksrare/utils";
import { TooltipText } from "@looksrare/uikit";

interface Props {
  spend: number;
  unlock: number;
  volumeToDateDisplay: string;
  volumeToUnlockAll: number;
}
export const UnlockTooltip = ({ spend, unlock, volumeToDateDisplay, volumeToUnlockAll }: Props) => {
  return (
    <Trans
      i18nKey="season1EndUnlockRateTooltip"
      values={{
        spend,
        unlock,
        volumeToDateDisplay,
        volumeToUnlockAll: formatNumberToLocale(volumeToUnlockAll, 0),
      }}
    >
      <TooltipText sx={{ whiteSpace: "pre-line" }}>
        {"You can speed up your unlocks by using YOLO tokens to play games. For every {{spend}} YOLO you spend in a game, {{unlock}} YOLO tokens are unlocked!\n" +
          "\n" +
          "YOLO token play volume to date:"}
        <TooltipText as="span" bold>
          {" "}
          {"{{volumeToDateDisplay}}\n"}
        </TooltipText>

        <TooltipText as="span">
          {"Volume required to unlock all:"}
          <TooltipText as="span" bold>
            {" "}
            {"{{volumeToUnlockAll}}"}
          </TooltipText>
        </TooltipText>
      </TooltipText>
    </Trans>
  );
};
