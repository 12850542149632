import { Stack, StackProps, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Text, NextLink } from "@looksrare/uikit";
import { useAccount } from "wagmi";
import { sleep } from "@looksrare/utils";
import { INDEXER_REFETCH_DELAY_MS } from "@looksrare/config";
import { useUserSeasonRewards } from "@/hooks";
import { ClaimableTokenRewardsCard } from "@/views/Rewards/components/HistoricSeason/ClaimableTokenRewardsCard";
import { RewardsSources } from "@/views/Rewards/components/HistoricSeason/RewardsSources";
import { useUser } from "@/queries/user";
import { BlastRewardsCard } from "./BlastRewardsCard";
import { LiquidityRewardsCard } from "./LiquidityRewardsCard";
import { HistoricSeasonUserAllocationSummary } from "./HistoricSeasonUserAllocationSummary";

export const HistoricSeason = ({ ...props }: StackProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const { data: user } = useUser(address!, { enabled: !!address });

  const {
    data: userSeasonRewards,
    isLoading: isLoadingUserSeason,
    refetch: refetch,
  } = useUserSeasonRewards(address!, 1, {
    enabled: !!address,
  });

  const waitForIndexerAndRefetch = async () => {
    await sleep(INDEXER_REFETCH_DELAY_MS);
    return refetch();
  };

  return (
    <VStack w="100%" maxW={1200} mx="auto" spacing={16} alignItems="stretch" {...props}>
      <VStack spacing={4} alignItems="stretch">
        <Text textStyle="display-01" bold textAlign="center">
          {t("Season 1")}
        </Text>

        <Text maxW="650px" mx="auto" textAlign="center">
          {t("Claim your Season 1 rewards now! Play games to speed up your airdrop unlock, or claim over time. ")}
          <Text as={NextLink} href="https://docs.yologames.io/yolo-token/" color="link-01">
            {t("Learn more about the three distribution methods here.")}
          </Text>
        </Text>
        <Stack direction={{ base: "column", md: "row" }} spacing={4} flexWrap="wrap" flex={1} alignItems="stretch">
          <HistoricSeasonUserAllocationSummary flex={1} isLoading={isLoadingUserSeason} unlockRateMinutes={10} />

          <VStack spacing={4} alignItems="stretch" minWidth="340px" flex={1}>
            <ClaimableTokenRewardsCard
              borderColor="interactive-03"
              isLoading={isLoadingUserSeason}
              currentClaimableAmountWei={BigInt(
                userSeasonRewards?.yTokenVestingClaimDetails?.currentClaimableAmountWei || 0n
              )}
              hasPlayedGames={!!user?.hasPlayedGames}
              claimedWei={BigInt(userSeasonRewards?.yTokenVestingClaimDetails?.claimedWei || 0n)}
              proof={userSeasonRewards?.yTokenVestingClaimDetails?.proof}
              onSuccess={waitForIndexerAndRefetch}
            />

            <LiquidityRewardsCard
              isLoading={isLoadingUserSeason}
              currentClaimableAmountVum={BigInt(
                userSeasonRewards?.vaultSharesClaimDetails?.vaultSharesClaimableVum || 0n
              )}
              proof={userSeasonRewards?.vaultSharesClaimDetails?.proof}
              hasClaimed={!!userSeasonRewards?.vaultSharesClaimDetails?.claimedAt}
              onSuccess={waitForIndexerAndRefetch}
            />
            <BlastRewardsCard
              goldReceived={userSeasonRewards?.blastGoldAllocation || 0n}
              isLoading={isLoadingUserSeason}
            />
          </VStack>
        </Stack>
      </VStack>
      <VStack alignItems="stretch">
        <Text textStyle="display-body" bold>
          {t("Your YOLO Rewards Sources")}
        </Text>
        <RewardsSources />
      </VStack>
    </VStack>
  );
};
