import { AlertDescription, AlertTitle, Box, Button, ModalBody, ModalFooter } from "@chakra-ui/react";
import {
  Modal,
  ModalHeroHeader,
  ModalProps,
  WalletIcon,
  Text,
  ModalFooterStack,
  Alert,
  MetaMaskIcon,
  CheckmarkFilledIcon,
} from "@looksrare/uikit";
import { Trans, useTranslation } from "next-i18next";
import { useState } from "react";
import { ClaimLiquiditySharesAutoTransactionStepRow } from "@/components/Modals/ClaimLiquiditySharesModal/ClaimLiquiditySharesAutoStepRow";
import { AddYoloVaultSharesTokenToWalletButton } from "@/components/Buttons/AddTokenToWalletButton";

interface Props extends Omit<ModalProps, "children"> {
  onSuccess: () => void;
  proof: string[] | null | undefined;
  currentClaimableAmountVum: bigint;
}

export const ClaimLiquiditySharesModalBody = ({ onClose, proof, currentClaimableAmountVum }: Props) => {
  const { t } = useTranslation();
  const [view, setView] = useState<"confirm" | "transaction" | "success">("confirm");
  const rewardsToPoolPercentage = 30;

  return (
    <>
      {view === "confirm" && (
        <>
          <ModalHeroHeader
            showCloseButton
            onClose={onClose}
            icon={WalletIcon}
            title={t("Your YOLO tokens are earning even more YOLO!")}
            titleProps={{ textStyle: "heading-03" }}
            label={t(
              "{{rewardsToPoolPercentage}}% of your Season 1 YOLO rewards have been distributed to the YOLO Liquidity Pool.",
              { rewardsToPoolPercentage }
            )}
            labelProps={{ color: "link-01" }}
          />
          <ModalFooter>
            <Button flex={1} onClick={() => setView("transaction")}>
              {t("Claim Now")}
            </Button>
          </ModalFooter>
        </>
      )}

      {view === "transaction" && (
        <>
          <ModalHeroHeader
            showCloseButton
            onClose={onClose}
            icon={WalletIcon}
            title={t("Claim yYolo")}
            label="-" // @Note non empty to allow labelProps to be rendered
            labelProps={{
              as: () => (
                <Trans>
                  <Text fontWeight="initial" textAlign="center">
                    First{" "}
                    <Text color="link-01" as="span">
                      claim your &quot;yYolo&quot;{" "}
                    </Text>
                    - this token represents your share of the YOLO in the liquidity pool
                  </Text>
                </Trans>
              ),
            }}
          />
          <ModalBody pt={0}>
            <Box borderRadius="container" px={4} py={6} bg="ui-bg">
              <ClaimLiquiditySharesAutoTransactionStepRow
                proof={proof}
                currentClaimableAmountVum={currentClaimableAmountVum}
                onSuccess={() => setView("success")}
              />
            </Box>
          </ModalBody>
        </>
      )}

      {view === "success" && (
        <>
          <ModalHeroHeader
            showCloseButton
            onClose={onClose}
            icon={CheckmarkFilledIcon}
            title={t("yYolo Claimed")}
            label="-" // @Note non empty to allow labelProps to be rendered
            labelProps={{
              as: () => (
                <Trans>
                  <Text fontWeight="initial" textAlign="center">
                    You&apos;ll{" "}
                    <Text color="link-01" as="span">
                      earn YOLO{" "}
                    </Text>
                    when people play eligible games using their YOLO tokens, and you’ll{" "}
                    <Text color="link-01" as="span">
                      earn Droplets{" "}
                    </Text>
                    just for keeping your YOLO in the Pool!
                  </Text>
                </Trans>
              ),
            }}
          />
          <ModalBody color="text-inverse">
            <Alert status="warning" bg="support-warning-bg-inverse">
              <Box>
                <AlertTitle color="text-inverse">{t("Don’t sell or transfer your yYOLO!")}</AlertTitle>
                <AlertDescription textStyle="detail">
                  You’ll need yYOLO to withdraw your YOLO from the Pool. If you sell it, the new owner will be able to
                  claim that YOLO instead.
                </AlertDescription>
              </Box>
            </Alert>
          </ModalBody>
          <ModalFooterStack stackProps={{ direction: "column" }}>
            <AddYoloVaultSharesTokenToWalletButton colorScheme="secondary" gap={2}>
              <MetaMaskIcon />
              {t("Add yYOLO to metamask")}
            </AddYoloVaultSharesTokenToWalletButton>
            <Button colorScheme="secondary" onClick={onClose}>
              {t("Got it")}
            </Button>
          </ModalFooterStack>
        </>
      )}
    </>
  );
};

export const ClaimLiquiditySharesModal = (props: Props) => {
  return (
    <Modal {...props}>
      <ClaimLiquiditySharesModalBody {...props} />
    </Modal>
  );
};
