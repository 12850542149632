import { Box, chakra, Divider, Flex, HStack, StackProps, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { formatToSignificant, fromDecimals } from "@looksrare/utils";
import { HelpIcon, NextLink, Popover, Text, TokenYoloIcon, TooltipText } from "@looksrare/uikit";
import { useAccount } from "wagmi";
import { useUserSeasonRewards } from "@/hooks";
import { HistoricSeasonUserAllocationChart } from "./HistoricSeasonUserAllocationChart";
import { DataPoint } from "./DataPoint";
import { UnlockTooltip } from "./UnlockTooltip";

const responsiveMargin = {
  base: 4,
  lg: 6,
};

interface Props extends StackProps {
  unlockRateMinutes: number;
  isLoading: boolean;
}

export const HistoricSeasonUserAllocationSummary = ({ isLoading, unlockRateMinutes, ...props }: Props) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const { data: userSeasonRewards } = useUserSeasonRewards(address!, 1, { enabled: !!address });

  Number(userSeasonRewards?.yTokenVestingClaimDetails.currentClaimableAmountWei || 0);

  const readyToClaim = BigInt(userSeasonRewards?.yTokenVestingClaimDetails.currentClaimableAmountWei || 0n);
  const readyToClaimNum: number = parseFloat(fromDecimals(readyToClaim));

  const claimedNum = parseFloat(fromDecimals(userSeasonRewards?.yTokenVestingClaimDetails.claimedWei || 0n));

  const claimedDisplayNum: number = (() => {
    // ONLY For the sake of displaying user assets, we consider allocated YOLO as claimed
    const claimed = parseFloat(fromDecimals(userSeasonRewards?.yTokenVestingClaimDetails.claimedWei || 0n));

    const allocatedToLP = parseFloat(
      fromDecimals(userSeasonRewards?.vaultSharesClaimDetails.yTokensDepositedWei || 0n)
    );
    return claimed + allocatedToLP;
  })();

  const lockedNum: number = (() => {
    const allocation = parseFloat(
      fromDecimals(userSeasonRewards?.yTokenVestingClaimDetails.vestingAllocationWei || 0n)
    );
    return allocation - readyToClaimNum - claimedNum;
  })();

  const volumeToDate = BigInt(userSeasonRewards?.yTokenVestingClaimDetails.volumeWei || 0n);
  const volumeToDateDisplay: string = formatToSignificant(volumeToDate, 0, { decimals: 18 });
  const unlockedByPlayingDisplay = formatToSignificant(volumeToDate / 100n);

  const volumeToUnlockAll: number = parseFloat(
    fromDecimals(userSeasonRewards?.yTokenVestingClaimDetails.remainingVolumeToClaimWei || 0n)
  );

  const unlockStats = {
    spend: 1000,
    unlock: 10,
    volumeToDateDisplay,
    volumeToUnlockAll,
  };

  return (
    <VStack
      borderRadius="dialog"
      alignItems="stretch"
      borderWidth="1px"
      bg="ui-01"
      spacing={0}
      divider={<Divider />}
      flex={1}
      {...props}
    >
      <Box m={responsiveMargin}>
        <HStack alignItems="center" mb={4}>
          <Text textStyle="detail" color="text-03" bold>
            {t("Your S1 YOLO Allocation")}
          </Text>
          <Popover
            label={
              <TooltipText>
                {t(
                  "{{percentage}}% of Season 1 YOLO token rewards were unlocked immediately at the end of the season. \n" +
                    "Every {{unlockRateMinutes}} minutes, more YOLO tokens are unlocked and available to claim.",
                  { percentage: 20, unlockRateMinutes }
                )}
              </TooltipText>
            }
          >
            <chakra.span>
              <HelpIcon color="text-03" mb={1} boxSize="20px" />
            </chakra.span>
          </Popover>
        </HStack>
        <HistoricSeasonUserAllocationChart claimed={claimedDisplayNum} unlocked={readyToClaimNum} locked={lockedNum} />
      </Box>
      <VStack spacing={4} alignItems="stretch" m={responsiveMargin}>
        <Box>
          <Text>{t("Speed up your unlocking by using YOLO to play eligible games! ")}</Text>
          <Text color="link-01" as={NextLink} href="https://docs.yologames.io/yolo-token/season-one-claim">
            {t("Learn More")}
          </Text>
        </Box>
        <Flex gap={2}>
          <DataPoint
            isLoading={isLoading}
            value={
              <>
                {volumeToDateDisplay} <TokenYoloIcon boxSize="16px" />
              </>
            }
            label={
              <>
                {t("Eligible volume to date")}
                <Popover label={<UnlockTooltip {...unlockStats} />}>
                  <chakra.span>
                    <HelpIcon boxSize="12px" />
                  </chakra.span>
                </Popover>
              </>
            }
          />
          <DataPoint
            isLoading={isLoading}
            value={
              <>
                {unlockedByPlayingDisplay}
                <TokenYoloIcon boxSize="16px" />
              </>
            }
            label={
              <>
                {t("Unlocked early by playing")}
                <Popover label={<UnlockTooltip {...unlockStats} />}>
                  <chakra.span>
                    <HelpIcon boxSize="12px" ml={1} />
                  </chakra.span>
                </Popover>
              </>
            }
          />
        </Flex>
      </VStack>
    </VStack>
  );
};
