import { Button, Divider, Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { zeroAddress } from "viem";
import {
  Chevron,
  ExternalLink,
  GradientBorder,
  LevelsFilledIcon,
  NextLink,
  Popover,
  ProfileAvatar,
  Text,
  TooltipText,
} from "@looksrare/uikit";
import { formatNumberToLocale } from "@looksrare/utils";
import { gradients } from "@looksrare/chakra-theme";
import { useUserSeasonPoints } from "@/hooks";
import { useUser } from "@/queries/user";

export const UserPointsDisplay = () => {
  const { t } = useTranslation();
  const { address: connectedAddress } = useAccount();
  const userQuery = useUser(connectedAddress!, { enabled: !!connectedAddress });
  const { data: userPoints } = useUserSeasonPoints(connectedAddress!, { enabled: !!connectedAddress });

  return (
    <GradientBorder
      borderRadius="dialog"
      bgGradient="linear(to-t, ui-gradient-rainbow-a, ui-gradient-rainbow-b)"
      innerProps={{ bgGradient: gradients["ui-gradient-v-01"] }}
    >
      <Stack spacing={8} width={{ base: "100%", lg: "377px", md: "314px" }}>
        <Stack spacing={4}>
          <Stack spacing={4}>
            <Flex justifyContent="space-between">
              <Stack>
                <ProfileAvatar
                  size={48}
                  address={connectedAddress ?? zeroAddress}
                  src={userQuery.data?.avatar?.image.src}
                  isPrivate={userQuery.data?.isProfileImageVisible === false}
                />
              </Stack>
              <Button
                as={NextLink}
                leftIcon={<LevelsFilledIcon />}
                rightIcon={<Chevron direction="right" />}
                href="/rewards/leaderboard"
                size="xs"
                colorScheme="secondary"
              >
                Rank {userPoints?.seasonPoints?.seasonRank ? `#${userPoints?.seasonPoints.seasonRank}` : "#"}
              </Button>
            </Flex>
            <Flex alignItems="flex-end" flexDirection="row">
              <Text textStyle="display-03" bold>
                {userPoints?.seasonPoints?.seasonPoints
                  ? formatNumberToLocale(Number(userPoints?.seasonPoints.seasonPoints), 0, 0)
                  : "—"}
              </Text>
              <Text bold color="text-03" pl={1}>
                {t("Pts")}
              </Text>
            </Flex>
            <Divider borderColor="border-01" />

            <SimpleGrid columns={2} gap={4} width="100%">
              <Popover
                variant="tooltip"
                label={<TooltipText>{t("Points earned by playing games this season")}</TooltipText>}
              >
                <Stack marginTop="3" gap="0">
                  <Text bold>
                    {userPoints?.seasonPoints?.details?.games
                      ? formatNumberToLocale(Number(userPoints.seasonPoints.details.games), 0, 0)
                      : "—"}
                  </Text>
                  <Text color="text-03" textStyle="detail">
                    {t("Gaming Pts")}
                  </Text>
                </Stack>
              </Popover>

              <Popover variant="tooltip" label={<TooltipText>{t("Points earned from completing quests")}</TooltipText>}>
                <Stack marginTop="3" gap="0">
                  <Text bold>
                    {userPoints?.seasonPoints?.details?.quests
                      ? formatNumberToLocale(Number(userPoints.seasonPoints.details.quests), 0, 0)
                      : "—"}
                  </Text>
                  <Text color="text-03" textStyle="detail">
                    {t("Quest Pts")}
                  </Text>
                </Stack>
              </Popover>

              <Popover
                variant="tooltip"
                label={<TooltipText>{t("Points earned from inviting friends to YOLO Games this season")}</TooltipText>}
              >
                <Stack marginTop="3" gap="0">
                  <Text bold>
                    {userPoints?.seasonPoints?.details?.referrals
                      ? formatNumberToLocale(Number(userPoints.seasonPoints.details.referrals), 0, 0)
                      : "—"}
                  </Text>
                  <Text color="text-03" textStyle="detail">
                    {t("Referral Pts")}
                  </Text>
                </Stack>
              </Popover>
            </SimpleGrid>
          </Stack>
        </Stack>
        <Button
          as={ExternalLink}
          href="https://docs.yologames.io/rewards/points"
          isExternal
          width="full"
          colorScheme="secondary"
        >
          {t("Learn More")}
        </Button>
      </Stack>
    </GradientBorder>
  );
};
