import { AutoTransactionStepRow, TransactionSetter, useHandleModalStep, useToast } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useAccount, usePublicClient } from "wagmi";
import { Address } from "viem";
import { useGetFormattedErrorAndTitle } from "@looksrare/utils";
import { useSeasonLiquidityRewardsClaim } from "@/hooks/season1/seasonRewardsContracts";

interface Props {
  onSuccess: () => void;
  proof: string[] | null | undefined;
  currentClaimableAmountVum: bigint;
}

export const ClaimLiquiditySharesAutoTransactionStepRow = ({ onSuccess, proof, currentClaimableAmountVum }: Props) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();
  const publicClient = usePublicClient();
  const { toast } = useToast();

  const claim = useSeasonLiquidityRewardsClaim();

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    return useHandleModalStep({
      onSuccess: onSuccess,
      onSubmit: async () => {
        if (!publicClient) {
          throw new Error("No public client found");
        }
        if (!proof) {
          // user has no rewards or BE claiming service is not available yet
          throw new Error("No rewards or BE claiming service is not available yet");
        }

        const hash = await claim({ address: address!, amount: currentClaimableAmountVum, proof: proof as Address[] });
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          setTransaction(undefined);
          onSuccess();
        } else {
          throw new Error(`Claiming YOLO failed. Transaction hash ${receipt.transactionHash}`);
        }
      },
      onError: (error: any) => {
        const { title, description } = getFormattedErrorAndTitle(error);
        toast({ title, description, status: "error", dataIdSuffix: "claim-refund" });
      },
    });
  };

  return <AutoTransactionStepRow useHandleTransaction={useHandleTransaction} isStepActive ctaText={t("Transaction")} />;
};
