import { Flex, HStack, StackProps, VStack, chakra } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { BlastGoldTokenIcon, Button, ExternalLink, HelpIcon, Popover, Text, TooltipText } from "@looksrare/uikit";
import { BigIntish, formatNumberToLocale } from "@looksrare/utils";
import { DataPoint } from "./DataPoint";

interface Props extends StackProps {
  goldReceived: BigIntish;
  isLoading: boolean;
}

export const BlastRewardsCard = ({ goldReceived, isLoading, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <VStack
      borderRadius="dialog"
      bg="ui-01"
      p="24px"
      borderColor="border-01"
      borderWidth="1px"
      alignItems="stretch"
      spacing={4}
      {...props}
    >
      <HStack alignItems="center">
        <Text textStyle="detail" color="text-03" bold>
          {t("BLAST Gold")}
        </Text>
        <Popover
          label={
            <TooltipText>
              {t("Season 1 Blast Gold rewards are managed by Blast. Confirm your distribution on the Blast website.")}
            </TooltipText>
          }
        >
          <chakra.span>
            <HelpIcon color="text-03" mb={1} boxSize="16px" />
          </chakra.span>
        </Popover>
      </HStack>
      <Flex>
        <DataPoint
          flex={1}
          isLoading={isLoading}
          value={
            <>
              {formatNumberToLocale(Number(goldReceived), 0)}
              <BlastGoldTokenIcon boxSize="16px" />
            </>
          }
          label={t("Total")}
        />
        <Button flex={1} as={ExternalLink} href="https://blast.io/en/airdrop" colorScheme="secondary">
          {t("View Blast")}
        </Button>
      </Flex>
    </VStack>
  );
};
