import { StackProps, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Text } from "@looksrare/uikit";

export const DataPoint = ({
  value,
  label,
  isLoading,
  ...props
}: { value: ReactNode; label: ReactNode; isLoading?: boolean } & StackProps) => {
  return (
    <VStack alignItems="stretch" spacing={0} flex={1} {...props}>
      <Text bold textStyle="display-body" alignItems="center" display="flex" gap={1}>
        {isLoading ? "—" : value}
      </Text>
      <Text textStyle="helper" color="text-03" display="flex" gap={1}>
        {label}
      </Text>
    </VStack>
  );
};
