import { Flex, FlexProps } from "@chakra-ui/react";
import { formatNumberToLocale, formatToSignificant } from "@looksrare/utils";
import { TokenYoloIcon, TokenYoloShardIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { DataPoint } from "@/views/Rewards/components/HistoricSeason/DataPoint";
import { useUserSeasonRewards } from "@/hooks";

export const RewardsSources = (props: FlexProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { data: userSeasonRewards, isLoading } = useUserSeasonRewards(address!, 1, { enabled: !!address });

  const seasonPoints = Number(userSeasonRewards?.points || 0n);
  const seasonShards = Number(userSeasonRewards?.stakingShards || 0n);
  const fromPoints = BigInt(userSeasonRewards?.yTokenWeiAllocation || 0n);
  const fromLRShards = BigInt(userSeasonRewards?.yTokenWeiFromShards || 0n);

  return (
    <Flex gap={4} {...props} p={4} borderWidth="1px" borderRadius="dialog" flexWrap="wrap">
      <DataPoint
        value={
          <>
            {formatToSignificant(fromPoints, 0)}
            <TokenYoloIcon boxSize="16px" />
          </>
        }
        label={t("From S1 Points")}
        isLoading={isLoading}
      />
      <DataPoint
        value={`${formatNumberToLocale(seasonPoints, 0)} Pts`}
        label={<>{t("S1 Points")}</>}
        isLoading={isLoading}
      />
      {!!seasonShards && !!fromLRShards && (
        <>
          <DataPoint
            value={
              <>
                {formatToSignificant(fromLRShards, 0)} <TokenYoloIcon boxSize="16px" />
              </>
            }
            label={<>{t("From Shards on LooksRare")}</>}
            isLoading={isLoading}
          />
          <DataPoint
            value={
              <>
                {formatNumberToLocale(seasonShards, 0)}
                <TokenYoloShardIcon boxSize="16px" />
              </>
            }
            label={t("Shards")}
            isLoading={isLoading}
          />
        </>
      )}
    </Flex>
  );
};
