import { Button, CheckmarkIcon, Link, NextLink, Text, TwitterIcon } from "@looksrare/uikit";
import { Divider, Flex, Stack } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import range from "lodash/range";
import { formatToSignificant, sleep } from "@looksrare/utils";
import { useTranslation } from "next-i18next";

// Support component for ClaimTokenModalRequisiteSteps
const StepIndicators = ({ activeIndex, stepsCount }: { activeIndex: number; stepsCount: number }) => {
  return (
    <Stack spacing={0} alignItems="center">
      {range(stepsCount).map((step, index) => {
        const isComplete = activeIndex > index;
        const isFuture = activeIndex < index;

        const { stepIndicatorBg, stepIndicatorBorderColor, dividerColor, textColor } = (() => {
          if (isFuture) {
            return {
              stepIndicatorBg: "interactive-02",
              stepIndicatorBorderColor: "interactive-02",
              dividerColor: "interactive-02",
              textColor: "text-disabled",
            };
          }
          if (isComplete) {
            return {
              stepIndicatorBg: "interactive-03",
              stepIndicatorBorderColor: "interactive-03",
              dividerColor: "interactive-03",
              textColor: "text-primarybutton",
            };
          }

          return {
            stepIndicatorBg: "transparent",
            stepIndicatorBorderColor: "interactive-01",
            dividerColor: "interactive-01",
            textColor: "text-01",
          };
        })();

        return (
          <Flex key={index} flexDirection="column" alignItems="center">
            {index !== 0 && <Divider height="25px" orientation="vertical" borderColor={dividerColor} />}
            <Flex
              width={12}
              height={12}
              borderRadius="button"
              border="1px solid"
              borderColor={stepIndicatorBorderColor}
              bg={stepIndicatorBg}
              alignItems="center"
              justifyContent="center"
            >
              <Text color={textColor}>{index + 1}</Text>
            </Flex>
          </Flex>
        );
      })}
    </Stack>
  );
};

// Support component for ClaimTokenModalRequisiteSteps
const ActionableStep = ({
  description,
  activeIndex,
  index,
  children,
}: {
  description: string;
  activeIndex: number;
  index: number;
  children: ReactNode;
}) => {
  const getTextColor = () => {
    if (activeIndex < index) {
      return "text-disabled";
    }
    if (activeIndex > index) {
      return "interactive-03";
    }
    return "text-01";
  };

  return (
    <Flex alignItems="center" gap={4} width="100%" height="48px" justifyContent="space-between">
      <Text bold color={getTextColor()} maxWidth={{ base: "168px", md: "100%" }}>
        {description}
      </Text>
      <Flex height="100%" justifyContent="flex-end">
        {activeIndex === index && children}
        {activeIndex > index && <CheckmarkIcon color="interactive-03" boxSize={12} />}
      </Flex>
    </Flex>
  );
};

interface Props {
  hasPlayedGames: boolean;
  onClaim: () => void;
  currentClaimableAmountWei: bigint;
}

export const ClaimTokenModalRequisiteSteps = ({ hasPlayedGames, onClaim, currentClaimableAmountWei }: Props) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(hasPlayedGames ? 1 : 0);

  useEffect(() => {
    // Wait 5 fake seconds to nudge users to share on Twitter, then assume they did
    if (hasPlayedGames) {
      sleep(5000).then(() => setActiveIndex(2));
    }
  }, [hasPlayedGames]);

  const tweetAttachedUrl = "https://yologames.io";
  const tweetText = t(
    "I'm claiming {{amount}} YOLO — the native token for Blast's hottest on-chain gaming venue.\n" +
      "\n" +
      "Start earning Points for Season 2's rewards pool now!",
    { amount: formatToSignificant(currentClaimableAmountWei, 2) }
  );

  return (
    <Flex width="100%" gap={4}>
      <StepIndicators stepsCount={3} activeIndex={activeIndex} />
      <Stack spacing="25px" width="100%">
        <ActionableStep description={t("Play at least one game")} activeIndex={activeIndex} index={0}>
          <Button minWidth="120px" as={NextLink} href="/yolo-limited">
            {t("Play")}
          </Button>
        </ActionableStep>
        <ActionableStep description={t("Share on Twitter")} activeIndex={activeIndex} index={1}>
          <Button
            minWidth="120px"
            as={Link}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
              tweetAttachedUrl
            )}&text=${encodeURIComponent(tweetText)}`}
            gap={1}
          >
            <TwitterIcon /> {t("Share")}
          </Button>
        </ActionableStep>
        <ActionableStep description={t("Claim in Wallet")} activeIndex={activeIndex} index={2}>
          <Button minWidth="120px" onClick={onClaim}>
            {t("Claim")}
          </Button>
        </ActionableStep>
      </Stack>
    </Flex>
  );
};
