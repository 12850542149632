import { Box, BoxProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Text, TextProps } from "@looksrare/uikit";
import { MilestoneCode } from "@/types/points";

interface DescriptionTextProps extends BoxProps {
  code: MilestoneCode;
  textProps?: TextProps;
}
export const DescriptionText = ({ code, textProps, ...props }: DescriptionTextProps) => {
  const { t } = useTranslation();

  // Minimum multiplier and eth enter amount to start earning
  // milestones and points on classic games.
  const multiplierAmount = "1.5x";
  const playAmount = "0.0015";

  const content = (() => {
    switch (code) {
      case "POINTS":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Collect Points, earn even more Points. Oh yeahhhh.")}
          </Text>
        );
      case "PROFILE_SETUP":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Earn Points for setting up your profile! Once you’re fully set up you’ll earn an extra completion bonus."
            )}
          </Text>
        );
      case "MORD_ROUND":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Play MOON or DOOM rounds, earn Points.")}
          </Text>
        );
      case "MORD_WIN":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Win rounds of MOON or DOOM, earn Points.")}
          </Text>
        );
      case "MORD_WIN_STREAK":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Earn Points by winning rounds of MOON or DOOM in a row. Wins do not need to be in consecutive rounds.")}
          </Text>
        );
      case "MORD_ETH":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Get Points by entering MOON or DOOM rounds with ETH.")}
          </Text>
        );
      case "PTB_ETH":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Enter a cumulative total of ETH across any number of PTB rounds during the season. Rounds must go ahead and not be canceled to count towards this quest."
            )}
          </Text>
        );
      case "PTB_WIN":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Win a cumulative total of rounds across any number of PTB rounds during the season. Rounds must go ahead and not be canceled to count towards this milestone. Win means... not losing."
            )}
          </Text>
        );
      case "PTB_WIN_STREAK":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Win rounds consecutively without getting mauled. Your wins don’t need to be in the same cave to count for this milestone: just consecutive."
            )}
          </Text>
        );
      case "PTB_FUTURE_ROUND":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Enter multiple future PTB rounds in the same transaction. Your total count will increase every time you enter multiple rounds at once; if you enter 10 rounds at once, then 15 rounds at once, your total for this milestone will be 25."
            )}
          </Text>
        );
      case "YOLO_ETH":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Enter a cumulative total of ETH or other assets (measured by their ETH value) across any number of YOLO rounds during the season. Rounds must go ahead and not be canceled to count towards this milestone."
            )}
          </Text>
        );
      case "YOLO_WIN":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Win a cumulative total of rounds across any number of YOLO rounds during the season. Rounds must go ahead and not be canceled to count towards this milestone."
            )}
          </Text>
        );
      case "YOLO_ROUND":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Play YOLO rounds to earn Points. Rounds must be concluded successfully to be eligible.")}
          </Text>
        );
      case "YOLO_FUTURE_ROUND":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Enter multiple future YOLO rounds in the same transaction. Your total count will increase every time you enter multiple rounds at once; if you enter 10 rounds at once, then 15 rounds at once, your total for this milestone will be 25."
            )}
          </Text>
        );
      case "FLIPPER_ETH":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Get Points by playing Flipper with YOLO and/or ETH.")}
          </Text>
        );
      case "FLIPPER_ROUND":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Play rounds of Flipper with {{playAmount}} ETH or equivalent YOLO to earn Points.", {
              playAmount,
            })}
          </Text>
        );
      case "FLIPPER_WIN":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Win rounds of Flipper when playing with {{playAmount}} ETH or equivalent YOLO, earn Points.", {
              playAmount,
            })}
          </Text>
        );
      case "FLIPPER_WIN_STREAK":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Win consecutive rounds of Flipper {{playAmount}} ETH and/or equivalent YOLO.", {
              playAmount,
            })}
          </Text>
        );

      case "QUANTUM_ETH":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Get Points by playing Quantum with YOLO and/or ETH.")}
          </Text>
        );

      case "QUANTUM_ROUND":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Play rounds of Quantum with {{playAmount}} ETH or equivalent YOLO to earn Points. Your multiplier must be at least {{multiplierAmount}}.",
              {
                playAmount,
                multiplierAmount,
              }
            )}
          </Text>
        );

      case "QUANTUM_WIN":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Win rounds of Quantum with {{playAmount}} ETH or equivalent YOLO, earn Points. Your multiplier must be at least {{multiplierAmount}}.",
              {
                playAmount,
                multiplierAmount,
              }
            )}
          </Text>
        );

      case "QUANTUM_WIN_STREAK":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Win consecutive rounds of Quantum with with {{playAmount}} ETH and/or equivalent YOLO. Your multiplier must be at least {{multiplierAmount}}.",
              { playAmount, multiplierAmount }
            )}
          </Text>
        );

      case "DONT_FALL_IN_ETH":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Get Points by playing Don't Fall In with YOLO and/or ETH.")}
          </Text>
        );

      case "DONT_FALL_IN_ROUND":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Play rounds of Don't Fall In with {{playAmount}} ETH or equivalent YOLO to earn Points. Your multiplier must be at least {{multiplierAmount}}.",
              {
                playAmount,
                multiplierAmount,
              }
            )}
          </Text>
        );

      case "DONT_FALL_IN_WIN":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Win rounds of Don't Fall In with {{playAmount}} ETH or equivalent YOLO, earn Points. Your multiplier must be at least {{multiplierAmount}}.",
              {
                playAmount,
                multiplierAmount,
              }
            )}
          </Text>
        );

      case "DONT_FALL_IN_WIN_STREAK":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t(
              "Win consecutive rounds of Don't Fall In with with {{playAmount}} ETH and/or equivalent YOLO. Your multiplier must be at least {{multiplierAmount}}.",
              {
                playAmount,
                multiplierAmount,
              }
            )}
          </Text>
        );

      case "LASER_BLAST_ETH":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Get Points by playing LaserBlast with YOLO and/or ETH.")}
          </Text>
        );

      case "LASER_BLAST_ROUND":
        return (
          <Text color="text-02" textAlign="center" {...textProps}>
            {t("Play rounds of LaserBlast with {{playAmount}} ETH or equivalent YOLO to earn Points.", {
              playAmount,
            })}
          </Text>
        );

      default:
        return null;
    }
  })();

  return (
    <Box width="100%" {...props}>
      {content}
    </Box>
  );
};
