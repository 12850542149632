import { ModalBody, Box } from "@chakra-ui/react";
import {
  AutoTransactionStepRow,
  Button,
  CheckmarkFilledIcon,
  MetaMaskIcon,
  Modal,
  ModalFooterStack,
  ModalHeroHeader,
  ModalProps,
  TransactionSetter,
  useHandleModalStep,
  WalletIcon,
} from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { differenceInSeconds } from "date-fns";
import { useAccount, usePublicClient } from "wagmi";
import { Address } from "viem";
import { formatToSignificant } from "@looksrare/utils";
import { useSeasonRewardsClaim } from "@/hooks/season1/seasonRewardsContracts";
import { AddYoloTokenToWalletButton } from "@/components/Buttons/AddTokenToWalletButton";
import { ClaimTokenModalRequisiteSteps } from "@/views/Rewards/components/HistoricSeason/ClaimTokenModalRequisiteSteps";

/**
 * The contract is paused every 10 mins for about 10 seconds.
 * This function waits for the safe time to claim to reduce chances of users simulating a successful tx but failing the real one.
 */
const waitForSafeTime = (startDate: Date) => {
  const minutes = startDate.getMinutes();
  const seconds = startDate.getSeconds();
  if ((minutes % 10 === 0 && seconds < 10) || (minutes % 10 === 9 && seconds > 50)) {
    //We're too close to a pause window. The next safe time is 10 seconds after the closest 10-minute mark

    const nextSafeTime = (() => {
      const nextTime = new Date(startDate.getTime() + 10_000);
      nextTime.setSeconds(10);
      return nextTime;
    })();

    const secondsToWait = differenceInSeconds(nextSafeTime, startDate);

    return new Promise((resolve) => setTimeout(resolve, secondsToWait * 1000));
  }

  return;
};

interface ClaimTokenModalBodyProps extends Omit<ModalProps, "children"> {
  onSuccess: () => void;
  proof: string[] | null | undefined;
  currentClaimableAmountWei: bigint;
  claimedWei: bigint;
  hasPlayedGames: boolean;
}

const ClaimTokenModalBody = ({
  onSuccess,
  onClose,
  currentClaimableAmountWei,
  claimedWei,
  proof,
  hasPlayedGames,
}: ClaimTokenModalBodyProps) => {
  const { address } = useAccount();
  const [view, setView] = useState<"requisites-checklist" | "claim" | "success">(
    claimedWei === 0n ? "requisites-checklist" : "claim"
  );
  const [amountClaimed, setAmountClaimed] = useState<string>(formatToSignificant(currentClaimableAmountWei, 2));
  const [isWaitingSafeTime, setIsWaitingSafeTime] = useState(false);
  const publicClient = usePublicClient();
  const claim = useSeasonRewardsClaim();

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    const handler = useHandleModalStep({
      onSuccess: onSuccess,
      onSubmit: async () => {
        if (!proof || !publicClient) {
          // user has no rewards or BE claiming service is not available yet
          return;
        }
        // Contract requires this value to track the previously claimed total as well;
        const valueToClaim = claimedWei + currentClaimableAmountWei;

        setIsWaitingSafeTime(true);
        await waitForSafeTime(new Date());
        setIsWaitingSafeTime(false);

        const hash = await claim({ address: address!, amount: valueToClaim, proof: proof as Address[] });
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          setAmountClaimed(formatToSignificant(currentClaimableAmountWei, 2));
          setTransaction(undefined);
          setView("success");
        } else {
          throw new Error(`Claiming YOLO failed. Transaction hash ${receipt.transactionHash}`);
        }
      },
      onError: () => {
        setIsWaitingSafeTime(false);
      },
    });

    return {
      ...handler,
      isInitialized: handler.isInitialized || isWaitingSafeTime,
    };
  };

  const { t } = useTranslation();

  return (
    <>
      {view === "requisites-checklist" && (
        <>
          <ModalHeroHeader
            showCloseButton
            onClose={onClose}
            icon={WalletIcon}
            title={t("You're Nearly There!")}
            label={t("Play ANY game on YOLO Games to get access to your unlocked YOLO! ")}
            labelProps={{ color: "text-02" }}
          />
          <ModalBody>
            <ClaimTokenModalRequisiteSteps
              hasPlayedGames={hasPlayedGames}
              onClaim={() => setView("claim")}
              currentClaimableAmountWei={currentClaimableAmountWei}
            />
          </ModalBody>
        </>
      )}

      {view === "claim" && (
        <>
          <ModalHeroHeader
            showCloseButton
            onClose={onClose}
            icon={WalletIcon}
            title={t("Claim Unlocked YOLO")}
            label={t("Open your wallet app and confirm the transaction.")}
            labelProps={{ color: "text-02" }}
          />
          <ModalBody>
            <Box p={4} bg="ui-bg" borderRadius="container">
              <AutoTransactionStepRow
                useHandleTransaction={useHandleTransaction}
                isStepActive
                ctaText={t("Claim YOLO")}
              />
            </Box>
          </ModalBody>
        </>
      )}

      {view === "success" && (
        <>
          <ModalHeroHeader
            showCloseButton
            onClose={onClose}
            icon={CheckmarkFilledIcon}
            title={t("{{amount}} YOLO Claimed", { amount: amountClaimed })}
            label={t("Your tokens are now in your wallet.")}
            labelProps={{ color: "text-02" }}
          />
          <ModalFooterStack stackProps={{ direction: "column" }}>
            <AddYoloTokenToWalletButton colorScheme="secondary" gap={2}>
              <MetaMaskIcon />
              {t("Add YOLO to metamask")}
            </AddYoloTokenToWalletButton>
            <Button colorScheme="secondary" onClick={onClose}>
              {t("Close")}
            </Button>
          </ModalFooterStack>
        </>
      )}
    </>
  );
};

interface Props extends Omit<ModalProps, "children"> {
  onSuccess: () => void;
  proof: string[] | null | undefined;
  currentClaimableAmountWei: bigint;
  claimedWei: bigint;
  hasPlayedGames: boolean;
}

//Separate modal and body to reset state on close
export const ClaimTokenModal = (props: Props) => {
  return (
    <Modal {...props}>
      <ClaimTokenModalBody {...props} />
    </Modal>
  );
};
