import { BigIntish, NoPublicClientError, useAddressesByNetwork } from "@looksrare/utils";
import { useCallback } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { YGSeasonRewardsDistributor } from "@looksrare/config";
import { Address, Hash } from "viem";

interface CanClaimS2Params {
  address: Address;
  proof: Hash[];
  amount: BigIntish;
}

export const useSeasonRewardsCanClaim = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const addressesByNetwork = useAddressesByNetwork();

  return useCallback(
    async ({ address, amount, proof }: CanClaimS2Params) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }

      const contractInfo = {
        address: addressesByNetwork.YG_SEASON_1_REWARDS_DISTRIBUTOR,
        abi: YGSeasonRewardsDistributor,
      };

      const [canClaim, claimableAmount] = await publicClient.readContract({
        ...contractInfo,
        functionName: "canClaim",
        args: [address, BigInt(amount), proof],
      });

      return { canClaim, claimableAmount };
    },
    [addressesByNetwork.YG_SEASON_1_REWARDS_DISTRIBUTOR, publicClient, walletClient]
  );
};

export const useSeasonRewardsClaim = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const addressesByNetwork = useAddressesByNetwork();

  return useCallback(
    async ({ address, amount, proof }: CanClaimS2Params) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }

      const contractInfo = {
        address: addressesByNetwork.YG_SEASON_1_REWARDS_DISTRIBUTOR,
        abi: YGSeasonRewardsDistributor,
      };

      const { request } = await publicClient.simulateContract({
        ...contractInfo,
        functionName: "claim",
        account: address,
        args: [BigInt(amount), proof],
      });

      const hash = await walletClient.writeContract(request);
      return hash;
    },
    [addressesByNetwork.YG_SEASON_1_REWARDS_DISTRIBUTOR, publicClient, walletClient]
  );
};

export const useSeasonLiquidityRewardsClaim = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const addressesByNetwork = useAddressesByNetwork();

  return useCallback(
    async ({ address, amount, proof }: CanClaimS2Params) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }

      const contractInfo = {
        address: addressesByNetwork.YG_SEASON_1_SHARES_DISTRIBUTOR,
        abi: YGSeasonRewardsDistributor, // Same ABI as the rewards distributor
      };

      const { request } = await publicClient.simulateContract({
        ...contractInfo,
        functionName: "claim",
        account: address,
        args: [BigInt(amount), proof],
      });

      const hash = await walletClient.writeContract(request);
      return hash;
    },
    [addressesByNetwork.YG_SEASON_1_SHARES_DISTRIBUTOR, publicClient, walletClient]
  );
};
