import { Box, Flex, HStack, VStack, useToken } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Text, HighCharts } from "@looksrare/uikit";
import { formatNumberToLocale } from "@looksrare/utils";

interface Props {
  claimed: number;
  unlocked: number;
  locked: number;
}

export const HistoricSeasonUserAllocationChart = ({ claimed, unlocked, locked }: Props) => {
  const { t } = useTranslation();
  const [claimedColor, lockedColor, unlockedColor] = useToken("colors", ["acid.200", "gray.700", "orange.400"]);
  const boxSize = "192px";

  // Render as zero rather than NaN when no rewards are remaining.
  const unlockedPercentageFn = () => {
    const remainingToClaim = locked + unlocked;
    const ratio = 1 - remainingToClaim / (claimed + remainingToClaim);

    if (claimed === 0) {
      return `0%`;
    } else {
      return remainingToClaim === 0 ? `100%` : `${formatNumberToLocale(ratio * 100, 0, 1)}%`;
    }
  };

  const unlockedPercentage = unlockedPercentageFn();

  // Set the locked amount as non-zero to render the charts
  // even when distribution hasn't yet been finalised.
  const lockedDisplay = locked === 0 && claimed === 0 ? Number.MIN_VALUE : locked;

  const donutOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      height: boxSize,
      margin: 0,
      backgroundColor: "transparent",
      styledMode: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
      },
    },

    series: [
      {
        type: "pie",
        colors: [claimedColor, unlockedColor, lockedColor],
        innerSize: "65%",
        data: [claimed, unlocked, lockedDisplay].map((value) => ({
          y: value,
        })),

        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <>
      <Flex gap="24px" alignItems="center" direction={{ base: "column", sm: "row" }}>
        <Flex boxSize={boxSize} position="relative" alignItems="center" justifyContent="center" flex={1}>
          <HighCharts pointerEvents="none" options={donutOptions} />
          <Box position="absolute" textAlign="center">
            <Text textStyle="heading-03" bold>
              {unlockedPercentage}
            </Text>
            <Text textStyle="detail" color="text-03" textAlign="center">
              {t("Claimed")}
            </Text>
          </Box>
        </Flex>
        <VStack alignItems="stretch" flex={1} spacing="14px" width="100%">
          <HStack>
            <Box boxSize={3} borderRadius="circular" bg={claimedColor} />
            <Text flex={1} textStyle="detail">
              {t("Claimed")}
            </Text>
            <Text textStyle="detail" bold>
              {formatNumberToLocale(claimed, 0)}
            </Text>
          </HStack>
          <HStack>
            <Box boxSize={3} borderRadius="circular" bg={unlockedColor} />
            <Text flex={1} textStyle="detail">
              {t("Claimable")}
            </Text>
            <Text textStyle="detail" bold>
              {formatNumberToLocale(unlocked, 0)}
            </Text>
          </HStack>
          <HStack>
            <Box boxSize={3} borderRadius="circular" bg={lockedColor} />
            <Text textStyle="detail" flex={1}>
              {t("Locked")}
            </Text>
            <Text textStyle="detail" bold>
              {formatNumberToLocale(locked, 0)}
            </Text>
          </HStack>
          <HStack borderTopWidth="1px" pt={2}>
            <Text textStyle="detail" flex={1} color="text-03">
              {t("Total")}
            </Text>
            <Text textStyle="detail" bold color="text-03">
              {formatNumberToLocale(claimed + unlocked + locked, 0)}
            </Text>
          </HStack>
        </VStack>
      </Flex>
    </>
  );
};
